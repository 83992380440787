import React, { useEffect, useState } from "react"
import { inject, observer } from "mobx-react"
import { Divider } from "@material-ui/core"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import { useStaticQuery, graphql } from "gatsby"
import { useQueryParam } from "gatsby-query-params"
import Modal from "../components/modal"

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const grayText = "#979a9d"

const Text = styled.div`
  font: 400 16px/20px "Poppins", sans-serif;
  color: #979a9d;
`

const ContentWrapper = styled(Text)`
  width: 100%;
  padding: 0px 4px;
  margin-bottom: 10px;
`

const PageLayout = styled.div`
  display: grid;
  font-family: Helvetica;
  width: 100%;
`

const CartContent = styled.div`
  display: grid;
  margin: 0 auto;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 700px;
  margin-top: 120px;
  text-align: left;

  h2 {
    font: 500 20px/1 "Poppins", sans-serif;
    color: #374048;
    margin: 0;
  }

  @media only screen and (max-width: 860px) {
    grid-template-columns: 1fr;
  }
`

const CartSubheader = styled.div`
  font-size: 13px;
  text-transform: uppercase;
  font-weight: 700;
  padding-left: 4px;
  color: ${grayText};
`

const OrderSummary = styled.div`
  color: ${grayText};
  border: 1px solid rgba(0, 0, 0, 0.08);
`

const OrderContent = styled.div`
  padding: 16px;
`

const OrderSummaryHeader = styled.div`
  font: 700 15px/1.5 "Poppins", sans-serif;
  text-transform: uppercase;
  color: #374048;
  height: 60px;
  padding-left: 16px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #111;
  background-color: rgb(247, 247, 247);
`

const OrderLineItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 14px 0px;
  color: black;
`

const FormSubHeader = styled(CartSubheader)`
  margin: 16px 0;
  color: black;
`

const PaymentSection = styled.div`
  width: 100%;
`

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginBottom: "16px",
  },
  heading: {
    borderRadius: 0,
    boxShadow: 0,
    background: "#201f1f",
    fontSize: "15px",
    fontWeight: 700,
    fontFamily: "Poppins,sans-serif",
    color: "white",
  },

  input: {
    color: "black",
    width: "100%",
    borderRadius: 0,
  },

  button: {
    backgroundColor: "#4A90E2",
    color: "white",
    height: "56px",
  },

  icon: {
    color: "green",
  },
}))

const shippingLabel = {
  standard: "Standard Shipping 5-7 days",
  priority: "Priority Shipping 2-3 days",
}

const ShippingAddressSummary = ({ formData }) => {
  return (
    <>
      <ContentWrapper>
        {formData.displayName ? (
          <div>{formData.displayName}</div>
        ) : (
          <div>
            {formData.firstName} {formData.lastName}
          </div>
        )}
        <div>
          {formData.address}, {formData.suite}
        </div>
        <div>{formData.zip}</div>
      </ContentWrapper>
    </>
  )
}

const Confirmation = styled.div`
  margin: 0 auto 50px;
  width: 100%;
  text-align: center;
  h2 {
    font-size: 23px;
    font-weight: 600;
    margin: 17px;
  }
  svg {
    width: 100px;
    height: 100px;
  }

  > div {
    margin: 12px;
  }
`
// move this config to util folder
const firebaseConfig = {
  apiKey: "AIzaSyC4yYUJumZOgMSgVZY5esFF8-gi807pI6g",
  authDomain: "lock-pole-defender.firebaseapp.com",
  projectId: "lock-pole-defender",
}

let functions
let firebaseGetOrder
let orderId

const OrderConfirmationPage = ({ cart: cartStore }) => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "front-side.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1000) {
            src
            srcSet
          }
        }
      }
    }
  `)
  const [isLoading, setIsLoading] = useState(false)
  const frontSide = data.file.childImageSharp.fluid.src
  const {
    creditCardInfo,
    orderConfirmationData: { line_items = [], fulfillments = [] },
  } = cartStore

  const recipient =
    fulfillments && fulfillments.length
      ? fulfillments[0]?.shipment_details?.recipient
      : {}

  const shippingInfo = {
    displayName: recipient?.display_name,
    firstName: recipient?.first_name,
    lastName: recipient?.last_name,
    address: recipient?.address?.address_line_1,
    zip: recipient?.address?.postal_code,
  }

  orderId = useQueryParam("order") || cartStore.orderId

  const setLoadingState = bool => {
    setIsLoading(bool)
  }

  useEffect(() => {
    setLoadingState(true)
    import("firebase").then(module => {
      const { default: firebase } = module
      if (firebase) {
        if (!firebase.apps.length) {
          firebase.initializeApp(firebaseConfig)
        }

        if (process.env.NODE_ENV === "development") {
          firebase.functions().useEmulator("localhost", 5001)
        }

        firebaseGetOrder = firebase.functions().httpsCallable("getOrder")
      }

      if (orderId && firebaseGetOrder) {
        setLoadingState(true)
        firebaseGetOrder(orderId)
          .then(response => {
            const {
              data: {
                data: { order = {} },
              },
            } = response

            setLoadingState(false)
            cartStore.setOrderConfirmationData(order)
          })
          .catch(err => {
            setLoadingState(false)
            console.log(err)
          })
      }
    })
  }, [])

  function confirmationAbr(confirmation) {
    if (confirmation) {
      const stringLength = [...confirmation].length || 0
      const iterable = [...confirmation].slice(stringLength - 6, stringLength)
      return `${iterable.join("")}`
    }
    return ""
  }

  return (
    <Layout handleCartClick={() => {}} hideSubNav hideNav>
      <SEO title="Home" />
      <PageLayout>
        <CartContent>
          <div>
            {isLoading && (
              <>
                <Modal
                  type="success"
                  title="Retrieving Order"
                  message="We are getting your order details. Please wait.."
                  isLoading={isLoading}
                />
              </>
            )}
            <Confirmation>
              <CheckCircleIcon />
              <h2>Your order is confirmed!</h2>
              <div>
                <strong>Order Reference code:</strong>{" "}
                <span>
                  {confirmationAbr(cartStore.orderConfirmationData.id)}
                </span>
              </div>
              <div>
                <strong> Order Name:</strong>{" "}
                <span>{recipient.display_name}</span>
              </div>
            </Confirmation>
            <OrderSummary>
              <OrderSummaryHeader>Order Summary</OrderSummaryHeader>
              <OrderContent>
                {line_items.map((item, i) => {
                  if (item.name !== "Shipping") {
                    return (
                      <Box display="flex" key={`${item.name}-${i}`}>
                        <Box mr="16px">
                          <img src={frontSide} width="100px" />
                        </Box>
                        <Box>
                          <div>{item.name}</div>
                          <div>qty X {item.quantity}</div>
                          <div>{item.variation_name}</div>
                        </Box>
                      </Box>
                    )
                  }
                })}
                {line_items.map(item => (
                  <OrderLineItem>
                    <CartSubheader>
                      {item.name} - ({item.variation_name}) X {item.quantity}
                    </CartSubheader>
                    {formatter.format(
                      item.variation_total_price_money?.amount / 100
                    )}
                  </OrderLineItem>
                ))}
                <OrderLineItem>
                  <CartSubheader>
                    Taxes - (7.25%) for CA residents
                  </CartSubheader>
                  {formatter.format(
                    (cartStore.orderConfirmationData?.net_amounts?.tax_money
                      ?.amount || 0) / 100
                  )}
                </OrderLineItem>
                <Divider orientation="horizontal" variant="fullWidth" light />
                {cartStore.promoDiscountAmount > 0 && (
                  <OrderLineItem>
                    <CartSubheader>Discount- (Promo code)</CartSubheader>- (
                    {formatter.format(
                      (cartStore.orderConfirmationData?.total_discount_money
                        ?.amount || 0) / 100
                    )}
                    )
                  </OrderLineItem>
                )}
                <Divider orientation="horizontal" variant="fullWidth" light />
                <OrderLineItem>
                  <CartSubheader>Total Cost </CartSubheader>
                  {formatter.format(
                    (cartStore.orderConfirmationData?.net_amounts?.total_money
                      ?.amount || 0) / 100
                  )}
                </OrderLineItem>
              </OrderContent>
            </OrderSummary>
            <div style={{ width: "100%" }}>
              <Box display="flex" justifyContent="space-between">
                <FormSubHeader>Shipping Information</FormSubHeader>
              </Box>
              <ShippingAddressSummary
                formData={shippingInfo}
                email={cartStore.contactInfo.email.value}
                phone={cartStore.contactInfo.phone.value}
                shippingOption={cartStore.formData.shippingSelection.value}
              />
            </div>
            <PaymentSection>
              <Box display="flex" justifyContent="space-between">
                <FormSubHeader>Payment Information</FormSubHeader>
              </Box>
              <Box>
                <ContentWrapper>
                  {creditCardInfo.card_brand}
                  {creditCardInfo.last_4}
                </ContentWrapper>
              </Box>
            </PaymentSection>
            <div style={{ width: "100%" }}>
              {line_items.map(item => (
                <ContentWrapper>
                  <Box display="flex" justifyContent="space-between">
                    <div>
                      {item.name} - ({item.variation_name}) X {item.quantity}
                    </div>
                    {formatter.format(
                      item.variation_total_price_money?.amount / 100
                    )}
                  </Box>
                </ContentWrapper>
              ))}
              <ContentWrapper>
                <Box display="flex" justifyContent="space-between">
                  <div>Taxes - (7.25%) for CA residents</div>
                  {formatter.format(
                    (cartStore.orderConfirmationData?.net_amounts?.tax_money
                      ?.amount || 0) / 100
                  )}
                </Box>
              </ContentWrapper>
              {!!cartStore.orderConfirmationData?.total_discount_money
                ?.amount && (
                <ContentWrapper>
                  <Box display="flex" justifyContent="space-between">
                    <div>Discount Promo code</div>- (
                    {formatter.format(
                      (cartStore.orderConfirmationData?.total_discount_money
                        ?.amount || 0) / 100
                    )}
                    )
                  </Box>
                </ContentWrapper>
              )}
              <ContentWrapper>
                <Box display="flex" justifyContent="space-between">
                  <div>Sub Total </div>
                  {formatter.format(
                    (cartStore.orderConfirmationData?.net_amounts?.total_money
                      ?.amount || 0) / 100
                  )}
                </Box>
              </ContentWrapper>
            </div>
          </div>
        </CartContent>
      </PageLayout>
    </Layout>
  )
}

export default inject("cart")(observer(OrderConfirmationPage))
